import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Container from 'reactstrap/lib/Container';

import { CheckoutWrapper } from '@ttstr/components/Checkout';

const Checkout: React.FC = () => {
  const { t } = useTranslation();

  return (
    <article className="mt-5">
      <Container>
        <Helmet>
          <title>{t('CHECKOUT.TITLE')}</title>
        </Helmet>
        <h1 className="text-center mb-4">{t('CHECKOUT.TITLE')}</h1>
      </Container>
      <CheckoutWrapper />
    </article>
  );
};

export default React.memo(Checkout);
